import { useI18n } from "../../../hooks/useI18n";
import { dayjs } from "../../../../utils/dayjs";

type LogData = {
  first_names?: string;
  last_name?: string;
  birth_date?: string;
  gender?: string;
  [key: string]: string | undefined;
};

type Log = {
  type: string;
  data: LogData;
};

export const LogHealthRecordDetails = ({ log }: { log: Log }) => {
  const { t, locale } = useI18n();
  const update = log.type.includes("Hephaistos::HealthRecord::Updated");

  return (
    <>
      {log.data.first_names !== undefined && (
        <li key="first_names">
          {t("healthRecord.attributes.first_names")}: {log.data.first_names?.toUpperCase() ?? "..."}
        </li>
      )}

      {log.data.last_name !== undefined && (
        <li key="last_name">
          {t("healthRecord.attributes.last_name")}: {log.data.last_name?.toUpperCase() ?? "..."}
        </li>
      )}

      {log.data.gender !== undefined && (
        <li key="gender">
          {t("healthRecord.attributes.gender")}: {log.data.gender ?? "..."}
        </li>
      )}

      {log.data.birth_date !== undefined && (
        <li key="birth_date">
          {t("healthRecord.attributes.birth_date")}: {dayjs(log.data.birth_date).locale(locale).format("ll") ?? "..."}
        </li>
      )}

      {update && (
        <>
          {log.data.first_names === undefined && (
            <li key="first_names" style={{ textDecorationLine: "line-through" }}>
              {t("healthRecord.attributes.first_names")}
            </li>
          )}

          {log.data.last_name === undefined && (
            <li key="last_name" style={{ textDecorationLine: "line-through" }}>
              {t("healthRecord.attributes.last_name")}
            </li>
          )}

          {log.data.gender === undefined && (
            <li key="gender" style={{ textDecorationLine: "line-through" }}>
              {t("healthRecord.attributes.gender")}
            </li>
          )}

          {log.data.birth_date === undefined && (
            <li key="birth_date" style={{ textDecorationLine: "line-through" }}>
              {t("healthRecord.attributes.birth_date")}
            </li>
          )}
        </>
      )}
    </>
  );
};
