import { TextField, TextFieldProps, styled } from "@mui/material";

export const CustomStyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  input: {
    backgroundColor: "white",
    borderRadius: 8,
  },
  ".MuiInputBase-root:not(.Mui-focused):not(.Mui-disabled):hover": {
    fieldset: {
      borderColor: theme.palette.primary[400],
    },
  },
  ".MuiInputBase-root.Mui-disabled": {
    fieldset: {
      borderColor: theme.palette.neutral[200],
    },
  },
  ".MuiInputBase-root.Mui-error": {
    input: {
      color: theme.palette.error[500],
    },
  },
  fieldset: {
    borderColor: theme.palette.neutral[200],
  },
  label: {
    "&:not(.Mui-focused):not(.Mui-error):not(.Mui-disabled) .label": {
      opacity: 0.42,
      color: "black",
    },
    "&.Mui-error": {
      opacity: 1,
      color: theme.palette.error[500],
    },
    "&.Mui-disabled": {
      opacity: 0.5
    },
  },
}));

const StyledTextField = (props: TextFieldProps) => {
  const spanLabel = props.label ? <span className="label">{props.label}</span> : undefined;
  return <CustomStyledTextField {...props} type={props.type} label={spanLabel} />;
};

export default StyledTextField;
