import { Chip, Grid2, Box } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { parseRawEventLogSearchParams } from "../../../utils/searchParams";
import { ProfessionalAutocomplete } from "../professsional/ProfessionalAutocomplete";
import { useHealthRecord, useTeamMembers } from "../../../store";
import { useAppContext } from "../../hooks/useAppContext";
import { useQueries } from "../../providers/Dependencies";
import { dayjs } from "../../../utils/dayjs";
import { ArrowRightAlt } from "@mui/icons-material";
import { DatePicker, DateValidationError } from "@mui/x-date-pickers";
import { FieldChangeHandlerContext } from "@mui/x-date-pickers/internals";
import { Dayjs } from "dayjs";
import { debounce } from "lodash-es";
import { useI18n } from "../../hooks/useI18n";
import { DomainHealthRecord } from "../../../domain/healthRecord";

export function SearchAuditLogForm({ forcedRootId }: { forcedRootId?: string }) {
  const teamMembers = useTeamMembers();
  const { teamMembersQuery } = useQueries();
  const [rawSearchParams, setRawSearchParams] = useSearchParams();
  const { organizationId, teamId } = useAppContext();
  const { t } = useI18n();
  const today = dayjs();

  const searchParams = useMemo(() => {
    const params = parseRawEventLogSearchParams(rawSearchParams);
    if (forcedRootId) {
      params.rootId = forcedRootId;
    }
    return params;
  }, [rawSearchParams, forcedRootId]);

  const [afterDate, setAfterDate] = useState<Dayjs | null>(() =>
    searchParams.after ? dayjs.utc(searchParams.after) : forcedRootId ? today : searchParams.rootId ? null : today,
  );

  const [beforeDate, setBeforeDate] = useState<Dayjs | null>(() =>
    searchParams.before
      ? dayjs.utc(searchParams.before)
      : forcedRootId
        ? today.add(1, "day")
        : searchParams.rootId
          ? null
          : today.add(1, "day"),
  );

  const updateSearchParams = useCallback(
    (key: string, value: string | null) => {
      const currentParams = new URLSearchParams(rawSearchParams);

      if (value !== null) {
        currentParams.set(key, value);
      } else {
        currentParams.delete(key);
      }

      setRawSearchParams(currentParams);
    },
    [setRawSearchParams, rawSearchParams],
  );

  const handleDateChange = useCallback(
    (key: "after" | "before") => (value: Dayjs | null, context: FieldChangeHandlerContext<DateValidationError>) => {
      if (context.validationError) return;

      const dateValue = value ? value.toISOString() : dayjs().toISOString();
      updateSearchParams(key, dateValue);

      if (key === "after") {
        setAfterDate(value || dayjs().utc());
      } else {
        setBeforeDate(value || dayjs().utc().add(1, "day"));
      }
    },
    [updateSearchParams],
  );

  const debouncedAfterChange = useMemo(() => debounce(handleDateChange("after"), 500), [handleDateChange]);

  const debouncedBeforeChange = useMemo(() => debounce(handleDateChange("before"), 500), [handleDateChange]);

  useEffect(() => {
    if (organizationId && teamId) {
      teamMembersQuery.call({ organizationId, teamId });
    }
  }, [teamMembersQuery, organizationId, teamId]);

  const removeSearchParam = useCallback(
    (key: string) =>
      setRawSearchParams((params) => {
        const updatedParams = new URLSearchParams(params);
        updatedParams.delete(key);
        return updatedParams;
      }),
    [setRawSearchParams],
  );

  const professional = useMemo(
    () => teamMembers?.find((pro) => pro.member.id === searchParams.emittedBy),
    [teamMembers, searchParams.emittedBy],
  );

  const healthRecord = useHealthRecord(searchParams.rootId ?? "");

  return (
    <Box sx={{ p: 2 }}>
      <Grid2
        container
        spacing={{ xs: 2, md: 2 }}
        alignItems="center"
        direction={{ xs: "column", md: "row" }}
        sx={{ width: "100%", m: 0 }}
      >
        <Grid2 size={{ xs: 12, md: 3 }}>
          <ProfessionalAutocomplete
            key={professional?.member.id}
            value={professional}
            options={teamMembers ?? []}
            onChange={(_e, value) => {
              if (value?.member?.id) {
                updateSearchParams("emittedBy", value.member.id);
              } else {
                updateSearchParams("emittedBy", null);
              }
            }}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                height: "40px",
                "& fieldset": {
                  borderColor: "rgba(0, 0, 0, 0.23)",
                },
                "&:hover fieldset": {
                  borderColor: "rgba(0, 0, 0, 0.87)",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#1c63fe",
                },
              },
            }}
          />
        </Grid2>

        <Grid2 size={{ xs: 12, md: 3 }} sx={{ width: { xs: "100%", md: "auto" } }}>
          <DatePicker
            label={t("eventLogs.since")}
            name="after"
            value={afterDate}
            onChange={debouncedAfterChange}
            maxDate={today}
            sx={{
              width: "100%",
              "& .MuiInputBase-root": {
                height: "40px",
              },
            }}
          />
        </Grid2>

        <Grid2
          size={{ xs: 12, md: "auto" }}
          sx={{
            display: "flex",
            justifyContent: "center",
            py: { xs: 1, md: 0 },
          }}
        >
          <ArrowRightAlt
            color="action"
            sx={{
              transform: {
                xs: "rotate(90deg)",
                md: "rotate(0deg)",
              },
            }}
          />
        </Grid2>

        <Grid2 size={{ xs: 12, md: 3 }} sx={{ width: { xs: "100%", md: "auto" } }}>
          <DatePicker
            label={t("eventLogs.until")}
            name="before"
            value={beforeDate}
            onChange={debouncedBeforeChange}
            maxDate={today.add(1, "day")}
            minDate={afterDate ?? undefined}
            sx={{
              width: "100%",
              "& .MuiInputBase-root": {
                height: "40px",
              },
            }}
          />
        </Grid2>

        {searchParams.rootId && !forcedRootId ? (
          <Grid2 size={{ xs: 12, md: 2 }} sx={{ width: { xs: "100%", md: "auto" } }}>
            <Chip
              label={label(t("eventLogs.eventLinked"), healthRecord)}
              onDelete={() => removeSearchParam("rootId")}
              sx={{
                maxWidth: "100%",
                width: { xs: "100%", md: "auto" },
              }}
            />
          </Grid2>
        ) : (
          searchParams.rootId && (
            <Grid2 size={{ xs: 12, md: 2 }} sx={{ width: { xs: "100%", md: "auto" } }}>
              <Chip
                label={label(t("eventLogs.eventLinked"), healthRecord)}
                sx={{
                  maxWidth: "100%",
                  width: { xs: "100%", md: "auto" },
                }}
              />
            </Grid2>
          )
        )}
      </Grid2>
    </Box>
  );
}

function label(label: string, healthRecord: DomainHealthRecord | undefined) {
  if (!healthRecord) return label;
  return `${healthRecord.firstNames} ${healthRecord.lastName}`;
}
