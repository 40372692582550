import { memo, useCallback } from "react";
import { useEventLog, useMemberById } from "../../../store";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid2,
  Tooltip,
  Typography,
  Box,
  SxProps,
} from "@mui/material";
import { ExpandMore, Restore } from "@mui/icons-material";
import { theme } from "../../layout/Theme";
import { useI18n } from "../../hooks/useI18n";
import { Log } from "../../../domain/log";
import { LinkForIcon } from "../../components/mui/LinkForIcon";
import { dayjs } from "../../../utils/dayjs";
import { useParams, useSearchParams } from "react-router-dom";
import { MemoIconLog } from "./IconLog";
import {
  LogCertificationDetails,
  LogHealthProfileConditionSetDetails,
  LogHealthRecordDetails,
  LogHeathProfileValidatorRemovedDetails,
  LogSharedWithTeamDetails,
  LogSharingRequestDetails,
  LogUnsharedWithProDetails,
  LogVaccinationActDetails,
} from "./details";
import { ProfessionalName } from "../../../utils/name";

export function EventLogListItem({ logId }: { logId: string }) {
  const log = useEventLog(logId);
  const [_, setSearchParams] = useSearchParams();

  const handleRootIdWithFilter = useCallback(() => {
    setSearchParams((params) => ({ ...Object.fromEntries(params), rootId: log?.rootId ?? "" }));
  }, [log, setSearchParams]);

  return log ? (
    <Accordion
      elevation={0}
      disableGutters
      slotProps={{ transition: { unmountOnExit: true } }}
      sx={{
        "&:hover": {
          backgroundColor: theme.palette.neutral[100],
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{ ".MuiAccordionSummary-content": { margin: 0, fontSize: "1rem" } }}
      >
        <AuditLogListItemSummary log={log} />
      </AccordionSummary>
      <AccordionDetails>
        <EventLinkedButton handler={handleRootIdWithFilter} sx={{ marginBottom: 2, gap: 1 }} />
        <Box sx={{ ml: 2 }}>
          <LogDetails log={log} />
        </Box>
      </AccordionDetails>
    </Accordion>
  ) : null;
}

export const MemoEventLogListItem = memo(EventLogListItem);

const AuditLogListItemSummary = ({ log }: { log: Log }) => {
  const { t, locale } = useI18n();
  const { teamId, organizationId } = useParams();
  const fontColor = theme.palette.neutral[500];
  const dateFontColor = theme.palette.neutral[600];

  if (!teamId || !organizationId) return;

  const IconLogWithLink = ({ log, teamId, organizationId }: { log: Log; teamId: string; organizationId: string }) => {
    const link = linkByLogType({ log, teamId, organizationId });
    const content = <MemoIconLog type={log.type} />;

    if (!link) {
      return content;
    }

    return (
      <LinkForIcon to={link} state={{ origin: location.href }}>
        {content}
      </LinkForIcon>
    );
  };

  return (
    <Grid2
      container
      width="100%"
      sx={{
        color: fontColor,
        alignItems: "center",
      }}
    >
      <Grid2
        size={3}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
          color: dateFontColor,
        }}
      >
        <IconLogWithLink log={log} teamId={teamId} organizationId={organizationId} />
        {`${dayjs(log.createdAt).locale(locale).format("lll")}`}
      </Grid2>
      <Grid2 size={6.2}>{t(`eventLogs.${log.type}`)}</Grid2>
      <Grid2
        size={2.8}
        sx={{
          "&:hover": {
            cursor: "zoom-in",
            textDecoration: "underline",
          },
        }}
      >
        <EventEmitterName log={log} />
      </Grid2>
    </Grid2>
  );
};

function linkByLogType({ log, teamId, organizationId }: { log: Log; teamId: string; organizationId: string }) {
  const linkMapping = [
    {
      prefix: "Hephaistos::HealthRecord::SharingRequest::Created",
      link: `/organizations/${organizationId}/teams/${teamId}/health-records/${log.rootId}/shares`,
    },
    {
      prefix: "Hephaistos::HealthRecord::VaccinationAct::Certified",
      link: "",
    },
    {
      prefix: "Hephaistos::HealthRecord::VaccinationAct::Created",
      link: `/organizations/${organizationId}/teams/${teamId}/health-records/${log.rootId}/vaccination/${log.id}`,
    },
    {
      prefix: "Hephaistos::HealthRecord",
      link: `/organizations/${organizationId}/teams/${teamId}/health-records/${log.rootId}/edit`,
    },
  ].sort((a, b) => b.prefix.length - a.prefix.length);

  const matchedLink = linkMapping.find(({ prefix }) => log.type.startsWith(prefix));

  return matchedLink ? matchedLink.link : "";
}

export function EventEmitterName({ log }: { log: Log }) {
  const member = useMemberById(log?.emittedBy);

  const systemId = "00000000-0000-0000-0000-000000000000";
  const { t } = useI18n();

  return member ? ProfessionalName(member) : log?.emittedBy === systemId ? t("common.system") : "N/A";
}

function LogDetails({ log }: { log: Log }) {
  const { t } = useI18n();

  if (Object.entries(log.data).length === 0) {
    return <>{t("eventLogs.noDetail")}</>;
  }

  const typeMapping = [
    { prefix: "Hephaistos::HealthRecord::Unshared", Component: LogUnsharedWithProDetails },
    {
      prefix: "Hephaistos::HealthRecord::HealthProfile::ValidatorRemoved",
      Component: LogHeathProfileValidatorRemovedDetails,
    },
    { prefix: "Hephaistos::HealthRecord::HealthProfile::ConditionSet", Component: LogHealthProfileConditionSetDetails },
    { prefix: "Hephaistos::HealthRecord::VaccinationAct::Certified", Component: LogCertificationDetails },
    { prefix: "Hephaistos::HealthRecord::VaccinationAct", Component: LogVaccinationActDetails },
    { prefix: "Hephaistos::HealthRecord::SharingRequest::Created", Component: LogSharingRequestDetails },
    { prefix: "Hephaistos::HealthRecord::SharedWithTeam", Component: LogSharedWithTeamDetails },
    { prefix: "Hephaistos::HealthRecord", Component: LogHealthRecordDetails },
  ].sort((a, b) => b.prefix.length - a.prefix.length);

  const matchedType = typeMapping.find(({ prefix }) => log.type.startsWith(prefix));

  return matchedType ? <matchedType.Component log={log} /> : null;
}

export const EventLinkedButton = ({ handler: handleRootIdFilter, sx }: { handler: () => void; sx: SxProps }) => {
  const { t } = useI18n();

  return (
    <Tooltip title={t("eventLogs.eventLinkedTooltip")}>
      <Button variant="outlined" size="small" onClick={handleRootIdFilter} sx={sx}>
        <Restore fontSize="small" />
        <Typography>{t("eventLogs.eventLinked")}</Typography>
      </Button>
    </Tooltip>
  );
};
