import { EventLogApiLogQuerySearchParams } from "../queries/EventLogQuery";
import { dayjs } from "./dayjs";

export function parseRawEventLogSearchParams(urlSearchParams: URLSearchParams): EventLogApiLogQuerySearchParams {
  const rawParams = Object.fromEntries(urlSearchParams);
  return {
    ...(rawParams.emittedBy && { emittedBy: rawParams.emittedBy }),
    ...(rawParams.rootId && { rootId: rawParams.rootId }),
    ...(rawParams.after && { after: dayjs.utc(rawParams.after) }),
    ...(rawParams.before && { before: dayjs.utc(rawParams.before) }),
  };
}
