import { Business, FactCheck, TextSnippetOutlined } from "@mui/icons-material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import VaccinesOutlinedIcon from "@mui/icons-material/VaccinesOutlined";
import { useTheme } from "@mui/material";
import { memo } from "react";

export const IconLog = ({ type }: { type: string }) => {
  const theme = useTheme();
  const iconProps = {
    fontSize: "small" as const,
    sx: {
      color: theme.palette.primary[600],
    },
  };

  if (type.startsWith("Hephaistos::HealthRecord::VaccinationAct")) return <VaccinesOutlinedIcon {...iconProps} />;
  if (type.startsWith("Hephaistos::HealthRecord::HealthProfile")) return <FactCheck {...iconProps} />;
  if (type.startsWith("Hephaistos::HealthRecord::Note")) return <TextSnippetOutlined {...iconProps} />;
  if (type.startsWith("Hephaistos::HealthRecord::SharingRequest")) return <AssignmentIcon {...iconProps} />;
  if (type.startsWith("Hephaistos::HealthRecord")) return <AssignmentIndIcon {...iconProps} />;
  if (type.startsWith("Hephaistos::Organization")) return <Business {...iconProps} />;
  if (type.startsWith("Hephaistos::ProfessionalAccount")) return <GroupsIcon {...iconProps} />;
  if (type.startsWith("Hephaistos::Team")) return <GroupsIcon {...iconProps} />;
  if (type.startsWith("Unknown")) return <CalendarTodayOutlinedIcon {...iconProps} />;

  // Fallback icon
  return <CalendarTodayOutlinedIcon {...iconProps} />;
};

export const MemoIconLog = memo(IconLog);
