import { useEffect, useMemo, useState } from "react";
import { useQueries } from "../../providers/Dependencies";
import { useParams, useSearchParams } from "react-router-dom";
import { useLastEventLogsIds } from "../../../store";
import { MemoEventLogList } from "./EventLogList";
import StyledPaperEmpty from "../../components/mui/StyledPaperEmpty";
import { useI18n } from "../../hooks/useI18n";
import { parseRawEventLogSearchParams } from "../../../utils/searchParams";
import { Divider, LinearProgress, Box } from "@mui/material";
import { Loading } from "../../components/shared/Loading";
import { SearchAuditLogForm } from "./SearchAuditLogForm";
import { theme } from "../../layout/Theme";

export function IndexHealthRecordEventLog() {
  const { t } = useI18n();
  const queries = useQueries();
  const { teamId, organizationId, id } = useParams();
  const logs = useLastEventLogsIds();
  const [rawSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const searchParams = useMemo(() => {
    const params = parseRawEventLogSearchParams(rawSearchParams);
    if (id) {
      params.rootId = id;
    }
    return params;
  }, [rawSearchParams, id]);

  useEffect(() => {
    if (!organizationId || !teamId) {
      console.error("Missing organizationId or teamId.");
      return;
    }

    (async () => {
      try {
        setIsLoading(true);
        await queries.getEventLogQuery.call(organizationId, teamId, searchParams);
        await queries.teamHealthRecordsQuery.call(organizationId, teamId);
        await queries.allOrganizationsQuery.call();
      } catch (error) {
        setIsLoading(false);
        console.error("Failed to fetch event logs:", error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [
    queries.getEventLogQuery,
    organizationId,
    teamId,
    searchParams,
    queries.teamHealthRecordsQuery,
    queries.allOrganizationsQuery,
  ]);

  return (
    <>
      {logs ? (
        <>
          {isLoading ? <LinearProgress variant="query" /> : null}
          <Box sx={{ px: 2, py: 1, backgroundColor: theme.palette.background.paper, borderRadius: 1 }}>
            <SearchAuditLogForm forcedRootId={id} />
            <Divider />
          </Box>
          {logs.length > 0 ? (
            <MemoEventLogList logIds={logs} />
          ) : (
            <StyledPaperEmpty size="xl">{t("eventLogs.noLog")}</StyledPaperEmpty>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}
