import { useEffect, useState } from "react";
import { Log } from "../../../../domain/log";
import { useHealthRecord, useMemberById } from "../../../../store";
import { useArianeApi } from "../../../hooks";
import { useI18n } from "../../../hooks/useI18n";
import { Professional } from "@syadem/ariane-js";

export const LogSharingRequestDetails = ({ log }: { log: Log }) => {
  const { t } = useI18n();
  const [pro, setPro] = useState<Professional | null>(null);
  const healthRecord = useHealthRecord(log.rootId);
  const member = useMemberById(log.emittedBy);
  const { professionals } = useArianeApi();

  useEffect(() => {
    const fetchPro = async () => {
      try {
        const fetchedPro = await professionals.searchByRpps(log.data.shared_to_pro_external_id ?? "");
        setPro(fetchedPro);
      } catch (error) {
        console.error("Error fetching professional:", error);
      }
    };

    if (log.data.shared_to_pro_external_id) {
      fetchPro();
    }
  }, [log.data.shared_to_pro_external_id, professionals]);

  return (
    <>
      {log.data.health_record_id && (
        <li key="health_record_id">
          {t("sharingRequest.attributes.health_record_id")}:{" "}
          {healthRecord
            ? `${healthRecord?.identities?.[0]?.firstNames} ${healthRecord?.identities?.[0]?.lastName}`
            : "..."}
        </li>
      )}

      {log.data.shared_to && (
        <li key="shared_to">
          {t("sharingRequest.attributes.shared_to")}: {pro ? `${pro?.first_name} ${pro?.last_name}` : "..."}
        </li>
      )}

      {log.data.shared_by && (
        <li key="shared_by">
          {t("sharingRequest.attributes.shared_by")}: {member ? `${member?.firstName} ${member?.lastName}` : "..."}
        </li>
      )}
    </>
  );
};
