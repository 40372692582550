import { Box, Grid2, Typography } from "@mui/material";
import { theme } from "../../layout/Theme";
import { memo } from "react";
import { useI18n } from "../../hooks/useI18n";
import { MemoEventLogListItem } from "./EventLogListItem";

export const EventLogList = ({ logIds }: { logIds: string[] }) => {
  return (
    <Box sx={{ px: 2, py: 1, backgroundColor: theme.palette.background.paper, borderRadius: 1 }}>
      <EventLogHeader />
      {logIds.map((id) => (
        <MemoEventLogListItem logId={id} key={id} />
      ))}
    </Box>
  );
};

const EventLogHeader = () => {
  const { t } = useI18n();

  return (
    <Grid2
      container
      sx={{
        py: 1.5,
        px: { xs: 1, md: 2 },
        display: { xs: "none", md: "flex" },
      }}
    >
      <Grid2 size={3}>
        <Typography fontWeight={600}>{t("eventLogs.date")}</Typography>
      </Grid2>
      <Grid2 size={6}>
        <Typography fontWeight={600}>{t("eventLogs.event")}</Typography>
      </Grid2>
      <Grid2 size={3}>
        <Typography fontWeight={600}>{t("eventLogs.professional")}</Typography>
      </Grid2>
    </Grid2>
  );
};
export const MemoEventLogList = memo(EventLogList);
