import { Log } from "../../../../domain/log";
import { useI18n } from "../../../hooks/useI18n";
import { useMemberById } from "../../../../store";

export const LogHeathProfileValidatorRemovedDetails = ({ log }: { log: Log }) => {
  const { t } = useI18n();
  const member = useMemberById(log.data?.validator_id ?? "");

  return (
    <>
      {log.data.validator_id !== undefined && member && (
        <li key="validator_id">
          {t("healthProfileValidatorRemoved.attributes.validator_id")}:{" "}
          {member.firstName && member.lastName ? `${member.firstName} ${member.lastName}` : "..."}
        </li>
      )}
    </>
  );
};
