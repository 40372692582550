import { Log } from "../../../../domain/log";
import { useI18n } from "../../../hooks/useI18n";
import { useHealthRecord, useTeams } from "../../../../store";

export const LogSharedWithTeamDetails = ({ log }: { log: Log }) => {
  const { t } = useI18n();
  const teams = useTeams();
  const healthRecord = useHealthRecord(log.rootId);
  const healthRecordIdentity = healthRecord?.identities?.[0];

  const logDataWithHrId = { health_record_id: log.rootId, ...log.data };

  return (
    <>
      {logDataWithHrId.health_record_id !== undefined && (
        <li key="health_record_id">
          {t("sharedWithTeam.attributes.health_record_id")}:{" "}
          {healthRecordIdentity
            ? `${healthRecordIdentity.firstNames} ${healthRecordIdentity.lastName}`
            : t("healthRecord.unknown")}
        </li>
      )}

      {logDataWithHrId.team_id !== undefined && (
        <li key="team_id">
          {t("sharedWithTeam.attributes.team_id")}: {teams?.[logDataWithHrId.team_id]?.fullName ?? "..."}
        </li>
      )}

      {logDataWithHrId.from_team_id !== undefined && (
        <li key="from_team_id">
          {t("sharedWithTeam.attributes.from_team_id")}: {teams?.[logDataWithHrId.from_team_id]?.fullName ?? "..."}
        </li>
      )}
    </>
  );
};
