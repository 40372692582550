import { Box, PaperProps } from "@mui/material";
import StyledPaper from "./StyledPaper";

interface StyledPaperEmptyProps extends PaperProps {
  children: React.ReactNode;
  size?: "sm" | "md" | "l" | "xl";
}
const StyledPaperEmpty = ({ children, size, ...rest }: StyledPaperEmptyProps) => {
  const getBoxPadding = () => {
    switch (size) {
      case "sm":
        return 2;
      case "md":
        return 4;
      case "l":
        return 6;
      case "xl":
        return 8;
      default:
        return { xs: 1, md: 4 };
    }
  };
  return (
    <StyledPaper {...rest}>
      <Box sx={{ p: getBoxPadding(), textAlign: "center", fontWeight: "bold" }}>{children}</Box>
    </StyledPaper>
  );
};
export default StyledPaperEmpty;
