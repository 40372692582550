import { Log } from "../../../../domain/log";
import { useI18n } from "../../../hooks/useI18n";
import { useMemberById } from "../../../../store";

export const LogUnsharedWithProDetails = ({ log }: { log: Log }) => {
  const { t } = useI18n();
  const member = useMemberById(log.data?.account_id ?? "");

  return (
    <>
      {log.data.account_id && (
        <li key="account_id">
          {t("unsharedWithPro.attributes.account_id")}: {member ? `${member?.firstName} ${member?.lastName}` : "..."}
        </li>
      )}

      {log.data.account_type && (
        <li key="account_type">
          {t("unsharedWithPro.attributes.account_type")}: {log.data.account_type ?? "..."}
        </li>
      )}
    </>
  );
};
