import { Autocomplete, AutocompleteProps, AutocompleteRenderInputParams } from "@mui/material";
import { useI18n } from "../../hooks/useI18n";
import { FormatFirstName } from "../../../utils/name";
import StyledTextField from "../../components/mui/StyledTextField";
import { TeamMember } from "@syadem/kairos-team-js";
import { theme } from "../../layout/Theme";

export interface ProfessionalAutocompleteProps
  extends Omit<AutocompleteProps<TeamMember, false, true, false>, "renderInput"> {
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  required?: boolean;
}

export const ProfessionalAutocomplete = (props: ProfessionalAutocompleteProps) => {
  const { t } = useI18n();
  const { required } = props;

  const getOptionLabel = (teamMember: TeamMember): string => {
    const professional = teamMember.member;
    return `${professional?.lastName?.toUpperCase() || ""} ${FormatFirstName(professional?.firstName || "")}`;
  };

  return (
    <Autocomplete
      fullWidth
      size="small"
      noOptionsText={t("eventLogs.noOption")}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => <StyledTextField required={required} label={t("eventLogs.professional")} {...params} />}
      renderOption={(props, teamMember) => (
        <li {...props} key={teamMember.member.id}>
          {getOptionLabel(teamMember)}
        </li>
      )}
      sx={{
        backgroundColor: "background.paper",
        width: { xs: "100%", md: 300 },
        borderRadius: 1,
        ".MuiOutlinedInput-notchedOutline": { borderColor: theme.palette.neutral[300] },
      }}
      {...props}
    />
  );
};
