import { Log } from "../../../../domain/log";
import { useI18n } from "../../../hooks/useI18n";
import { dayjs } from "../../../../utils/dayjs";
import { useHealthRecord, useMemberById } from "../../../../store";
import { useDaphne } from "../../../hooks";
import { getTranslatedFieldByLocale } from "../../../../utils/translatedFields";

export const LogVaccinationActDetails = ({ log }: { log: Log }) => {
  const { t, locale } = useI18n();
  const update = log.type.includes("Hephaistos::HealthRecord::VaccinationAct::Updated");
  const member = useMemberById(log.emittedBy);
  const healthRecord = useHealthRecord(log.rootId);
  const healthRecordIdentity = healthRecord?.identities?.[0];
  const daphne = useDaphne();
  const vaccine = daphne.repositories.vaccines.find(log.data.vaccine_id ?? "");

  const {
    performed_on,
    vaccinator_id,
    health_record_id,
    vaccine_id,
    batch_number,
    serial_number,
    injection_method,
    injection_location,
    booster,
  } = log.data;

  return (
    <>
      {healthRecord && (
        <li key="patient_full_name">
          {t("vaccinationAct.attributes.patient_full_name")}: {healthRecord?.firstNames} {healthRecord?.lastName}
        </li>
      )}
      {performed_on && (
        <li key="performed_on">
          {t("vaccinationAct.attributes.performed_on")}: {dayjs(performed_on).locale(locale).format("ll")}
        </li>
      )}

      {vaccinator_id && (
        <li key="vaccinator_id">
          {t("vaccinationAct.attributes.vaccinator_id")}: {`${member?.firstName} ${member?.lastName}`}
        </li>
      )}

      {health_record_id && (
        <li key="health_record_id">
          {t("vaccinationAct.attributes.health_record_id")}:{" "}
          {healthRecordIdentity
            ? `${healthRecordIdentity?.firstNames} ${healthRecordIdentity?.lastName}`
            : t("healthRecord.unknown")}
        </li>
      )}

      {vaccine_id && (
        <li key="vaccine_id">
          {t("vaccinationAct.attributes.vaccine_id")}: {getTranslatedFieldByLocale(locale, vaccine?.name) ?? "..."}
        </li>
      )}

      {batch_number && (
        <li key="batch_number">
          {t("vaccinationAct.attributes.batch_number")}: {batch_number?.trim() ?? "..."}
        </li>
      )}

      {serial_number && (
        <li key="serial_number">
          {t("vaccinationAct.attributes.serial_number")}: {serial_number?.trim() ?? "..."}
        </li>
      )}

      {injection_method && (
        <li key="injection_method">
          {t("vaccinationAct.attributes.injection_method")}:{" "}
          {injection_method ? t(`vaccinationAct.injection_method.${injection_method}`) : "..."}
        </li>
      )}

      {injection_location && (
        <li key="injection_location">
          {t("vaccinationAct.attributes.injection_location")}:{" "}
          {injection_location ? t(`vaccinationAct.injection_location.${injection_location}`) : "..."}
        </li>
      )}

      {booster && (
        <li key="booster">
          {t("vaccinationAct.attributes.booster")}: {t(`vaccinationAct.booster.${booster}`) ?? "..."}
        </li>
      )}

      {update && (
        <>
          {performed_on && (
            <li key="performed_on" style={{ textDecorationLine: "line-through" }}>
              {t("vaccinationAct.attributes.performed_on")}
            </li>
          )}
          {vaccinator_id && (
            <li key="vaccinator_id" style={{ textDecorationLine: "line-through" }}>
              {t("vaccinationAct.attributes.vaccinator_id")}
            </li>
          )}
          {health_record_id && (
            <li key="health_record_id" style={{ textDecorationLine: "line-through" }}>
              {t("vaccinationAct.attributes.health_record_id")}
            </li>
          )}
          {vaccine_id && (
            <li key="vaccine_id" style={{ textDecorationLine: "line-through" }}>
              {t("vaccinationAct.attributes.vaccine_id")}
            </li>
          )}
          {batch_number && (
            <li key="batch_number" style={{ textDecorationLine: "line-through" }}>
              {t("vaccinationAct.attributes.batch_number")}
            </li>
          )}
          {serial_number && (
            <li key="serial_number" style={{ textDecorationLine: "line-through" }}>
              {t("vaccinationAct.attributes.serial_number")}
            </li>
          )}
          {injection_method && (
            <li key="injection_method" style={{ textDecorationLine: "line-through" }}>
              {t("vaccinationAct.attributes.injection_method")}
            </li>
          )}
          {injection_location && (
            <li key="injection_location" style={{ textDecorationLine: "line-through" }}>
              {t("vaccinationAct.attributes.injection_location")}
            </li>
          )}
          {booster && (
            <li key="booster" style={{ textDecorationLine: "line-through" }}>
              {t("vaccinationAct.attributes.booster")}
            </li>
          )}
        </>
      )}
    </>
  );
};
