import { useEffect, useMemo, useState } from "react";
import { useQueries } from "../../providers/Dependencies";
import { useParams, useSearchParams } from "react-router-dom";
import { useLastEventLogsIds } from "../../../store";
import { PageLayout } from "../../layout/PageLayout";
import { MemoEventLogList } from "./EventLogList";
import StyledPaperEmpty from "../../components/mui/StyledPaperEmpty";
import { useI18n } from "../../hooks/useI18n";
import { SearchAuditLogForm } from "./SearchAuditLogForm";
import { parseRawEventLogSearchParams } from "../../../utils/searchParams";
import { LinearProgress } from "@mui/material";
import { Loading } from "../../components/shared/Loading";

export function IndexTeamEventLog() {
  const { t } = useI18n();
  const queries = useQueries();
  const { teamId, organizationId } = useParams();
  const logs = useLastEventLogsIds();
  const [rawSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const searchParams = useMemo(() => parseRawEventLogSearchParams(rawSearchParams), [rawSearchParams]);

  useEffect(() => {
    if (!organizationId || !teamId) {
      console.error("Missing organizationId or teamId.");
      return;
    }

    (async () => {
      try {
        setIsLoading(true);
        await queries.getEventLogQuery.call(organizationId, teamId, searchParams);
        await queries.teamHealthRecordsQuery.call(organizationId, teamId);
        await queries.allOrganizationsQuery.call();
      } catch (error) {
        setIsLoading(false);
        console.error("Failed to fetch event logs:", error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [
    queries.getEventLogQuery,
    organizationId,
    teamId,
    searchParams,
    queries.teamHealthRecordsQuery,
    queries.allOrganizationsQuery,
  ]);

  return (
    <PageLayout title={t("eventLogs.title")} centerActions={<SearchAuditLogForm />}>
      {logs ? (
        <>
          {isLoading ? <LinearProgress variant="query" /> : null}
          {logs.length > 0 ? (
            <MemoEventLogList logIds={logs} />
          ) : (
            <StyledPaperEmpty size="xl">{t("eventLogs.noLog")}</StyledPaperEmpty>
          )}
        </>
      ) : (
        <Loading />
      )}
    </PageLayout>
  );
}
