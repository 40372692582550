import { Log } from "../../../../domain/log";
import { useI18n } from "../../../hooks/useI18n";
import { dayjs } from "../../../../utils/dayjs";
import { useDaphne } from "../../../hooks";
import { getTranslatedFieldByLocale } from "../../../../utils/translatedFields";
import { Dayjs } from "dayjs";
import { useHealthRecord } from "../../../../store";

export const LogHealthProfileConditionSetDetails = ({ log }: { log: Log }) => {
  const { t, locale } = useI18n();
  const conditionUnset = log.type.includes("Hephaistos::HealthRecord::HealthProfile::ConditionUnset");
  const daphne = useDaphne();
  const condition = daphne.repositories.conditions.find(log.data?.condition_id ?? "");
  const healthRecord = useHealthRecord(log.rootId);

  const isDate = (value: Dayjs): value is Dayjs => value instanceof Date && !isNaN(value.getTime());

  return (
    <>
      {healthRecord && (
        <li key="patient_full_name">
          {t("vaccinationAct.attributes.patient_full_name")}: {healthRecord?.firstNames} {healthRecord?.lastName}
        </li>
      )}
      {log.data.condition_id !== undefined && !conditionUnset && condition && (
        <li key="condition_id">
          {t("healthProfileConditionSet.attributes.condition_id")}:{" "}
          {getTranslatedFieldByLocale(locale, condition.label) ?? "..."}
        </li>
      )}

      {log.data.value !== undefined && !conditionUnset && (
        <li key="value">
          {t("healthProfileConditionSet.attributes.value")}:{" "}
          {log.data.value === null
            ? "..."
            : isDate(dayjs(log.data.value))
              ? dayjs(log.data.value).locale(locale).format("ll")
              : typeof log.data.value === "boolean" || typeof log.data.value === "number"
                ? String(log.data.value)
                : log.data.value}
        </li>
      )}

      {conditionUnset && log.data.condition_id !== undefined && (
        <li key="condition_unset">
          {t("healthProfileConditionSet.attributes.condition_id")}:{" "}
          {getTranslatedFieldByLocale(locale, condition?.label) ?? "..."}
        </li>
      )}
    </>
  );
};
