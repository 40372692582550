import { Log } from "../../../../domain/log";
import { useI18n } from "../../../hooks/useI18n";
import { dayjs } from "../../../../utils/dayjs";
import { useHealthRecord, useMemberById } from "../../../../store";

export const LogCertificationDetails = ({ log }: { log: Log }) => {
  const { t, locale } = useI18n();
  const member = useMemberById(log.data.certificator_id ?? "");
  const healthRecord = useHealthRecord(log.rootId);

  return (
    <>
      {healthRecord && (
        <li key="patient_full_name">
          {t("vaccinationAct.attributes.patient_full_name")}: {healthRecord?.firstNames} {healthRecord?.lastName}
        </li>
      )}
      {log.data.certified_on !== undefined && (
        <li key="certified_on">
          {t("certification.attributes.certified_on")}:{" "}
          {dayjs(log.data.certified_on).locale(locale).format("ll") ?? "..."}
        </li>
      )}

      {log.data.certification_method !== undefined && (
        <li key="certification_method">
          {t("certification.attributes.certification_method.title")}:{" "}
          {t(`certification.attributes.certification_method.${log.data.certification_method}`) ?? "..."}
        </li>
      )}
      {log.data.certificator_id !== undefined && member && (
        <li key="certificator_id">
          {t("certification.attributes.certificator_id")}:{" "}
          {member.firstName && member.lastName ? `${member.firstName} ${member.lastName}` : "..."}
        </li>
      )}
    </>
  );
};
